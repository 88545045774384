body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.side-bar {
  width: 15%;
}

.hg__header { grid-area: header;
  background: #BB001D; }
.hg__footer { grid-area: footer; }
.hg__main { grid-area: main; }
.hg__left { grid-area: navigation; }
.hg__right { grid-area: ads; }

.hg {
	display: grid;
	grid-template-areas: "header header header"
	                     "navigation main ads"
	                     "footer footer footer";
	grid-template-columns: 150px 1fr 150px;
	grid-template-rows: 50px 
						1fr
						30px;
  min-height: 100vh;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}

@media screen and (max-width: 600px) {
	.hg {
		grid-template-areas: "header"
		                     "navigation"
		                     "main"
		                     "ads"
		                     "footer";
		grid-template-columns: 100%;
		grid-template-rows: 100px 
							50px 
							1fr
							50px 
							30px;
  }
}

.form-error {
	color: rgb(187, 11, 11);
	font-weight: bold;
}

label {
  display: block;
}

form {
	margin-top: 10px;
}

.rt-td {
	white-space: normal !important
}

/* HORIZONTAL MENU */

